import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  DatePicker,
  Checkbox,
  Form,
  InputNumber,
  Select,
  Spin,
  message,
} from "antd";
import Image from "react-bootstrap/Image";

import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";
import calendarIcon from "@/assets/calendar.png";
import arrowIcon from "@/assets/arrow.png";

import { USCities } from "@/USCities";
import dayjs from "dayjs";

import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";

// import { glogEvent } from "firebase/analytics";
import { glogEvent } from "@/components/firebaseConfig";

import { useIntl } from "react-intl";

import { AppContext } from "@/Context";

import DatePickerLocaleEN from "antd/lib/date-picker/locale/en_US";
import DatePickerLocaleZH from "antd/lib/date-picker/locale/zh_CN";

const { RangePicker } = DatePicker;

export default function (props) {
  const { currentLanguage } = useContext(AppContext);

  const [addressForm] = Form.useForm();
  const [cargoForm] = Form.useForm();

  const intl = useIntl();

  const [messageApi, contextHolder] = message.useMessage();
  const showAlertMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        marginTop: "30vh",
      },
    });
  };

  const [isPallet, setIsPallet] = useState(true);

  const nextStep = () => {
    let addressInfo = addressForm.getFieldsValue();
    let cargoInfo = cargoForm.getFieldsValue();
    const pickup_zipcode = addressInfo["pickup_postal_code"];
    const pickup_item = USCities.find(
      (entry) => entry.value === pickup_zipcode
    );
    const delivery_zipcode = addressInfo["delivery_postal_code"];
    const delivery_item = USCities.find(
      (entry) => entry.value === delivery_zipcode
    );
    let quoteData = {
      price: priceString,

      pickup_zipcode: pickup_zipcode,
      pickup_city: pickup_item["city"],
      pickup_state: pickup_item["state"],

      delivery_zipcode: delivery_zipcode,
      delivery_city: delivery_item["city"],
      delivery_state: delivery_item["state"],

      is_pallet: isPallet,
      quantity: cargoInfo.quantity,
      length: cargoInfo.length,
      width: cargoInfo.width,
      height: cargoInfo.height,
      weight: cargoInfo.weight,

      pickup_ls: addressInfo.pickup_ls,
      pickup_type: addressInfo.pickup_type,
      delivery_ls: addressInfo.delivery_ls,
      delivery_type: addressInfo.delivery_type,

      pickup_date: cargoInfo.date[0].format("YYYY-MM-DD"),
      delivery_date: cargoInfo.date[1].format("YYYY-MM-DD"),
    };
    props.nextStep(quoteData);
  };

  useEffect(() => {
    updateQuoteButtonStatus();
  }, [isPallet]);

  const updateQuoteButtonStatus = () => {
    const addressInfo = addressForm.getFieldsValue();
    const cargoInfo = cargoForm.getFieldsValue();

    var quoteEnable = true;

    if (
      typeof addressInfo["delivery_postal_code"] === "undefined" ||
      addressInfo["delivery_postal_code"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["delivery_type"] === "undefined" ||
      addressInfo["delivery_type"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["pickup_postal_code"] === "undefined" ||
      addressInfo["pickup_postal_code"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["pickup_type"] === "undefined" ||
      addressInfo["pickup_type"] === null
    ) {
      quoteEnable = false;
    }

    if (
      typeof cargoInfo["date"] === "undefined" ||
      cargoInfo["date"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof cargoInfo["weight"] === "undefined" ||
      cargoInfo["weight"] === null
    ) {
      quoteEnable = false;
    }

    if (isPallet) {
      if (
        typeof cargoInfo["quantity"] === "undefined" ||
        cargoInfo["quantity"] === null
      ) {
        quoteEnable = false;
      }
    } else {
      if (
        typeof cargoInfo["height"] === "undefined" ||
        cargoInfo["height"] === null
      ) {
        quoteEnable = false;
      }
      if (
        typeof cargoInfo["length"] === "undefined" ||
        cargoInfo["length"] === null
      ) {
        quoteEnable = false;
      }
      if (
        typeof cargoInfo["width"] === "undefined" ||
        cargoInfo["width"] === null
      ) {
        quoteEnable = false;
      }
    }

    setQuoteButtonEnable(quoteEnable);
  };

  const [quoteButtonEnable, setQuoteButtonEnable] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [isGotPrice, setIsGotPrice] = useState(false);

  const fetchQuote = async (params) => {
    try {
      // Google analytics custom event log
      glogEvent("shipper_offer", params);

      setQuoteLoading(true);

      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/shipper/order/appraisal/anonymous`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${appCtx.token}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error(response);
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);

      if (result.code !== 0) {
        console.log(`request quote api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }

      console.log(result.data);
      setPickupCityString(params.pickup_city);
      setDeliveryCityString(params.delivery_city);
      setPriceString(`${result.data.price}`);
      setIsGotPrice(true);
    } catch (error) {
      console.log(`request login api error: ${error}`);
      messageApi.error("网络错误");
    } finally {
      setQuoteLoading(false);
    }
  };

  const [priceString, setPriceString] = useState("-.--");
  const [pickupCityString, setPickupCityString] = useState("");
  const [deliveryCityString, setDeliveryCityString] = useState("");


  const goOrder = (cargoInfo) => {
    if (window.innerWidth < 1000) {
      return goOrderMobile(cargoInfo);
    } else {
      return goOrderPc(cargoInfo);
    }
  }

  const goOrderMobile = (cargoInfo) => {
    return (
      <>
        <div style={{
          marginTop: 40,
          backgroundColor: "white",
          padding: "20px 15px",
          borderRadius: 16
        }}>
          <div style={{
            fontFamily: "PingFang SC",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "100%",
            letterSpacing: "0px",
            color: "var(--Orange, #EA7E30)",
            width: 256,
            height: 48
          }}>
            {useIntl().formatMessage({
              id: "page.quote.price.description",
              description: "以下为您此次货运的估价和预计送货时间：",
            })}
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
            }}>
              {/* 发货地址 */}
              <div style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 16
              }}>
                <div style={{
                  width: 20,
                  height: 20,
                  backgroundColor: "var(--Orange, #EA7E30)",
                  borderRadius: "50%",
                  marginRight: 6
                }}></div>
                <span style={{
                  fontFamily: "PingFang SC",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: '20px',
                  letterSpacing: "0px",
                }} >{pickupCityString}</span>
              </div>
              {/* 发货时间 */}
              <div style={{
                display: "flex",
              }}>
                <div
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 6

                }}>
                  <Image style={{
                    width: 20,
                    height: 20,
                  }} src={calendarIcon} />
                </div>
                <div style={{
                  fontFamily: "PingFang SC",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "100%",
                  letterSpacing: "0px",
                }}>{cargoInfo.date[0].format("YYYY-MM-DD")}</div>
              </div>

            </div>

            <div style={{
              width: "20%",
            }}>
              {/* 箭头 */}
              <Image style={{
                width: 30,
                height: '100%',
                marginRight: 10
              }} src={arrowIcon} />
            </div>

            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
            }}>
              {/* 收货地址 */}
              <div style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 16
              }}>
                <div style={{
                  width: 20,
                  height: 20,
                  backgroundColor: "var(--Orange, #EA7E30)",
                  borderRadius: "50%",
                  marginRight: 6
                }}></div>
                <span style={{
                  fontFamily: "PingFang SC",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: '20px',
                  letterSpacing: "0px",
                }} >{deliveryCityString}</span>
              </div>
              {/* 收货时间 */}
              <div style={{
                display: "flex",
              }}>
                <div style={{
                  width: 20,
                  height: 20,
                  marginRight: 6
                }}>
                  <Image style={{
                    width: 20,
                    height: 20,
                  }} src={calendarIcon} />
                </div>
                <div style={{
                  fontFamily: "PingFang SC",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "100%",
                  letterSpacing: "0px",
                }}>{cargoInfo.date[1].format("YYYY-MM-DD")}</div>
              </div>
          </div>

        </div>

        <div style={{
          marginTop: '30px',
          fontFamily: "PingFang SC",
          fontWeight: 600,
          fontSize: "26px",
          letterSpacing: "0px",
          verticalAlign: "middle",
          width: 105,
          color: "var(--Orange, #EA7E30)"
        }}>{`$ ${priceString}`}</div>
        
        </div>

        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <div
          style={{
            textAlign: "center",
            margin: "20px auto",
            background: "linear-gradient(166deg, #ea7e30 0%, #f5be51 100%)",
            width: 200,
            height: 46,
            borderRadius: "8px",
            cursor: "pointer",
            display: "inline-block",
            lineHeight: "46px",
            color: "#FFFFFF",
            fontWeight: 600,
          }}
          onClick={() => {
            nextStep();
          }}
        >
          <span>
            {useIntl().formatMessage({
              id: "page.quote.price.next.step",
              description: "去下单",
            })}
          </span>
        </div>
        </div>
        
      </>
    );
  }

  const goOrderPc = (cargoInfo) => {
    return (<div className="form-group mt-40">
    <p className="price-top-title">
      {useIntl().formatMessage({
        id: "page.quote.price.description",
        description: "以下为您此次货运的估价：",
      })}
    </p>
    <div className="price-bottom-box">
      <div className="left-box">
        <div className="address-item">
          <div className="top">
            <Image src={startIcon} className={"icon"} />
            <span className="title">
              {useIntl().formatMessage({
                id: "page.quote.price.pickup",
                description: "取货点",
              })}
            </span>
          </div>
          <span className="content">{pickupCityString}</span>
        </div>
        <div className="gradient-line"></div>
        <div className="address-item">
          <div className="top">
            <Image src={endIcon} className={"icon"} />
            <span className="title">
              {useIntl().formatMessage({
                id: "page.quote.price.delivery",
                description: "送货点",
              })}
            </span>
          </div>
          <span className="content">{deliveryCityString}</span>
        </div>
      </div>
      <span className="price-title">
        {useIntl().formatMessage({
          id: "page.quote.price.future",
          description: "预计",
        })}
      </span>
      <span className="price">{`$ ${priceString}`}</span>
      <div
        className="next-step-button"
        onClick={() => {
          nextStep();
        }}
      >
        <span>
          {useIntl().formatMessage({
            id: "page.quote.price.next.step",
            description: "去下单",
          })}
        </span>
      </div>
    </div>
  </div>)
  }

  return (
    <Container className="form-container">
      {contextHolder}
      <Form.Provider
        onFormChange={(changedFields, allFields) => {
          updateQuoteButtonStatus();
          let fields = allFields.changedFields;
          fields.forEach((field) => {
            glogEvent("quote_form_change", {
              field: field.name.join("."), // field name
              value: field.value,
            });
          });
        }}
      >
        <div class="form-group py_16" >
          <div class="form-group-title" style={{ marginBottom: "0px" }}>
            {useIntl().formatMessage({
                id: "page.quote.form.title",
                description: "免费估价",
              })}
          </div>
        </div>
        <div className="form-group mt-40 ">
          <div className="top-alert">
            <Image src={topAlertIcon} className={"top-alert-icon"} />
            <span>
              {useIntl().formatMessage({
                id: "page.quote.top.alert",
                description: "顶部提示 文案",
              })}
            </span>
          </div>
          <Form
            layout="vertical"
            variant="filled"
            name="address"
            form={addressForm}
            disabled={isGotPrice}
          >
            <p className="form-group-title">
              {useIntl().formatMessage({
                id: "page.quote.form.session.address",
                description: "地址信息 组标题",
              })}
            </p>
            <Row gutter={[80, 0]}>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.address.label.pickup",
                    description: "取货点 标题",
                  })}
                  name="pickup_postal_code"
                >
                  <Select
                    showSearch
                    placeholder={useIntl().formatMessage({
                      id: "page.quote.form.session.address.placeholder.pickup",
                      description: "取货点 输入占位",
                    })}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={USCities}
                  />
                </Form.Item>
                <Form.Item
                  name="pickup_ls"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>
                    {useIntl().formatMessage({
                      id: "page.quote.form.session.address.label.pickup.ls",
                      description: "取货点 升降尾板服务 标题",
                    })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.address.label.pickup.type",
                    description: "取货点 地址类别 标题",
                  })}
                  name="pickup_type"
                >
                  <Select>
                    <Select.Option value="1">
                      {useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.residential",
                        description: "地址类别 住宅区",
                      })}
                    </Select.Option>
                    <Select.Option value="0">
                      {useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.commercial",
                        description: "地址类别 商业区",
                      })}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.address.label.delivery",
                    description: "送货点 标题",
                  })}
                  name="delivery_postal_code"
                >
                  <Select
                    showSearch
                    placeholder={useIntl().formatMessage({
                      id: "page.quote.form.session.address.placeholder.delivery",
                      description: "送货点 输入占位",
                    })}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={USCities}
                  />
                </Form.Item>
                <Form.Item
                  name="delivery_ls"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>
                    {useIntl().formatMessage({
                      id: "page.quote.form.session.address.label.delivery.ls",
                      description: "送货点 升降尾板服务 标题",
                    })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.address.label.delivery.type",
                    description: "送货点 地址类别 标题",
                  })}
                  name="delivery_type"
                >
                  <Select>
                    <Select.Option value="1">
                      {useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.residential",
                        description: "地址类别 住宅区",
                      })}
                    </Select.Option>
                    <Select.Option value="0">
                      {useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.commercial",
                        description: "地址类别 商业区",
                      })}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="form-group mt-40">
          <Form
            layout="vertical"
            variant="filled"
            name="cargo"
            form={cargoForm}
            disabled={isGotPrice}
          >
            <p className="form-group-title">
              {useIntl().formatMessage({
                id: "page.quote.form.session.cargo",
                description: "货物信息 组标题",
              })}
            </p>
            <Form.Item
              label={useIntl().formatMessage({
                id: "page.quote.form.session.cargo.label.pickup.type",
                description: "交货形式 标题",
              })}
            >
              <Row gutter={[window.innerWidth < 800 ? 40 : 80, 0]}>
                <Col span={12}>
                  <div
                    className={`delivery-method-item ${
                      isPallet
                        ? "delivery-method-item-active"
                        : "delivery-method-item-normal"
                    }`}
                    onClick={() => {
                      if (!isGotPrice && !isPallet) {
                        setIsPallet(true);
                      }
                    }}
                  >
                    <Image
                      src={palletIcon}
                      className={"delivery-method-item-icon"}
                    />
                    <p className={"delivery-method-item-title"}>Pallet(s)</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    className={`delivery-method-item ${
                      isPallet
                        ? "delivery-method-item-normal"
                        : "delivery-method-item-active"
                    }`}
                    onClick={() => {
                      if (!isGotPrice && isPallet) {
                        setIsPallet(false);
                      }
                    }}
                  >
                    <Image
                      src={boxIcon}
                      className={"delivery-method-item-icon"}
                    />
                    <p className={"delivery-method-item-title"}>Box(es)</p>
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Row gutter={[80, 0]}>
              {isPallet ? (
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label={
                      <>
                        {useIntl().formatMessage({
                          id: "page.quote.form.session.cargo.label.quantity",
                          description: "货板数量 标题",
                        })}
                        <span className="quantity-des">
                          {useIntl().formatMessage({
                            id: "page.quote.form.session.cargo.label.sub.pallet.size",
                            description: "货板尺寸 副标题",
                          })}
                        </span>
                      </>
                    }
                    name="quantity"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} md={12} xl={12}>
                  <Row gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item
                        label={useIntl().formatMessage({
                          id: "page.quote.form.session.cargo.label.length",
                          description: "长 标题",
                        })}
                        name="length"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={useIntl().formatMessage({
                          id: "page.quote.form.session.cargo.label.width",
                          description: "宽 标题",
                        })}
                        name="width"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={useIntl().formatMessage({
                          id: "page.quote.form.session.cargo.label.height",
                          description: "高 标题",
                        })}
                        name="height"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.cargo.label.weight",
                    description: "货板重量 标题",
                  })}
                  name="weight"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0.01}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.form.session.cargo.label.date",
                    description: "取货时间 标题",
                  })}
                  name="date"
                >
                  <RangePicker
                    locale={
                      currentLanguage.locale.startsWith("zh")
                        ? DatePickerLocaleZH
                        : DatePickerLocaleEN
                    }
                    style={{ width: "100%" }}
                    minDate={dayjs()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Form.Provider>
      {!isGotPrice ? (
        <div className="quote-buttons-box mt-56">
          <Spin spinning={quoteLoading}>
            <div
              className={`quote-button ${
                quoteButtonEnable
                  ? "quote-button-enable"
                  : "quote-button-disable"
              }`}
              onClick={() => {
                const addressInfo = addressForm.getFieldsValue();
                const cargoInfo = cargoForm.getFieldsValue();

                if (
                  typeof addressInfo["pickup_postal_code"] === "undefined" ||
                  addressInfo["pickup_postal_code"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.address.alert.pickup",
                    description: "请选择取货地",
                  }));
                  addressForm.scrollToField("pickup_postal_code");
                  return;
                }
                if (
                  typeof addressInfo["pickup_type"] === "undefined" ||
                  addressInfo["pickup_type"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.address.alert.pickup.type",
                    description: "请选择取货地 地址类型",
                  }));
                  addressForm.scrollToField("pickup_type");
                  return;
                }

                if (
                  typeof addressInfo["delivery_postal_code"] === "undefined" ||
                  addressInfo["delivery_postal_code"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.address.alert.delivery",
                    description: "请选择送达地",
                  }));
                  addressForm.scrollToField("delivery_postal_code");
                  return;
                }
                if (
                  typeof addressInfo["delivery_type"] === "undefined" ||
                  addressInfo["delivery_type"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.address.alert.delivery.type",
                    description: "请选择送达地 地址类型",
                  }));
                  addressForm.scrollToField("delivery_type");
                  return;
                }

                if (!isPallet) {
                  if (
                    typeof cargoInfo["length"] === "undefined" ||
                    cargoInfo["length"] === null
                  ) {
                    showAlertMessage(intl.formatMessage({
                      id: "page.quote.form.session.cargo.alert.length",
                      description: "请填写货物长度",
                    }));
                    cargoForm.scrollToField("length");
                    return;
                  }
                  if (
                    typeof cargoInfo["width"] === "undefined" ||
                    cargoInfo["width"] === null
                  ) {
                    showAlertMessage(intl.formatMessage({
                      id: "page.quote.form.session.cargo.alert.width",
                      description: "请填写货物宽度",
                    }));
                    return;
                  }
                  if (
                    typeof cargoInfo["height"] === "undefined" ||
                    cargoInfo["height"] === null
                  ) {
                    showAlertMessage(intl.formatMessage({
                      id: "page.quote.form.session.cargo.alert.quantity",
                      description: "请填写货物高度",
                    }));
                    return;
                  }
                } else {
                  if (
                    typeof cargoInfo["quantity"] === "undefined" ||
                    cargoInfo["quantity"] === null
                  ) {
                    showAlertMessage(intl.formatMessage({
                      id: "page.quote.form.session.cargo.alert.quantity",
                      description: "请填写货板数量",
                    }));
                    return;
                  }
                }

                if (
                  typeof cargoInfo["weight"] === "undefined" ||
                  cargoInfo["weight"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.cargo.alert.weight",
                    description: "请填写货物总重量",
                  }));
                  return;
                }

                if (
                  typeof cargoInfo["date"] === "undefined" ||
                  cargoInfo["date"] === null
                ) {
                  showAlertMessage(intl.formatMessage({
                    id: "page.quote.form.session.cargo.alert.date",
                    description: "请选择取货时间",
                  }));
                  return;
                }

                if (quoteButtonEnable) {
                  const pickup_zipcode = addressInfo["pickup_postal_code"];
                  const pickup_item = USCities.find(
                    (entry) => entry.value === pickup_zipcode
                  );
                  const delivery_zipcode = addressInfo["delivery_postal_code"];
                  const delivery_item = USCities.find(
                    (entry) => entry.value === delivery_zipcode
                  );

                  var params = {
                    pickup_state: pickup_item["state"],
                    pickup_city: pickup_item["city"],
                    pickup_county: pickup_item["county"],
                    pickup_postal_code: pickup_zipcode,
                    delivery_state: delivery_item["state"],
                    delivery_city: delivery_item["city"],
                    delivery_county: delivery_item["county"],
                    delivery_postal_code: delivery_zipcode,
                    is_pallet: isPallet,
                    weight: cargoInfo["weight"],
                    pickup_type: addressInfo.pickup_type,
                    delivery_type: addressInfo.delivery_type,
                    pickup_ls: addressInfo.pickup_ls,
                    delivery_ls: addressInfo.delivery_ls,
                  };
                  if (isPallet) {
                    params["quantity"] = cargoInfo["quantity"];
                  } else {
                    params["length"] = cargoInfo["length"];
                    params["width"] = cargoInfo["width"];
                    params["height"] = cargoInfo["height"];
                  }
                  fetchQuote(params);
                }
                // console.log(cargoForm.getFieldsValue());
                // console.log(isPallet);
                // console.log(
                //   cargoForm.getFieldValue("date")[0].format("YYYY/MM/DD")
                // );
                // console.log(
                //   cargoForm.getFieldValue("date")[1].format("YYYY/MM/DD")
                // );
              }}
            >
              <span>
                {quoteLoading
                  ? " "
                  : useIntl().formatMessage({
                      id: "page.quote.form.submit.button",
                      description: "免费估价",
                    })}
              </span>
            </div>
          </Spin>
        </div>
      ) : goOrder(cargoForm.getFieldsValue())}
    </Container>
  );
}
