import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Toast from "react-bootstrap/Toast";

import picPhone from "@/assets/pic_phone@3x.png";
import iconQRCode from "@/assets/ic_qrcode@2x.png";
import italicTextLogo from "@/assets/italic_text_logo.png";
import iconCheck from "@/assets/ic_check@2x.png";
import iconWish from "@/assets/wish_icon.png";

import QrCodeImg from "@/assets/download-qrcode.png";
import { FormattedMessage } from "react-intl";
import { AppContext } from "@/Context";

import "./index.css";
import Hmain from "@/components/h";

export default function Home() {
  const { currentLanguage } = useContext(AppContext);
  const [showTopQRDownloadToast, setShowTopQRDownloadToast] = useState(false);
  const [showShipperQRDownloadToast, setShowShipperQRDownloadToast] = useState(false);
  const [showCarrierQRDownloadToast, setShowCarrierQRDownloadToast] = useState(false);


  return (
    <div className="body-wrapper">
      <div className="body-wrapper-0">
        <div className="container" style={{ paddingTop: window.innerWidth < 1000 ? "0" : undefined }}>
          <div className="bg-extra-space">
            <Hmain imgType="truck" />
          </div>
        </div>
        
      </div>
      <div className="body-wrapper-1">
        <Container>
          <center>
            <h1 className="for-shipper-title">
              <FormattedMessage id={"page.home.for-shipper-advantages"} />
            </h1>
            <div className="description">
              <FormattedMessage
                id={"page.home.for-shipper-description-first"}
              />
              <p>
                <FormattedMessage
                  id={"page.home.for-shipper-description-second"}
                />
              </p>
              <div className="video_player">
                <iframe
                  src={
                    currentLanguage.locale === "en"
                      ? "https://www.youtube.com/embed/AJlQlIpyIEY?si=7dBo0lq1Ej1VXUGR"
                      : currentLanguage.locale === "zh-CN"
                      ? "https://www.youtube.com/embed/36wZlPg9Qr8?si=SPKcqiVbMdKR8NCz"
                      : currentLanguage.locale
                  }
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </center>
          <Row className="body-wrapper-row">
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">01</span>
              <h2>
                <FormattedMessage id={"page.home.trustworthy-choice"} />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.trustworthy-choice-description"}
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">02</span>
              <h2>
                <FormattedMessage id={"page.home.efficiency-and-simplicity"} />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.efficiency-and-simplicity-description"}
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">03</span>
              <h2>
                <FormattedMessage id={"page.home.fair-pricing"} />
              </h2>
              <div className="box-message">
                <FormattedMessage id={"page.home.fair-pricing-description"} />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">04</span>
              <h2>
                <FormattedMessage
                  id={"page.home.catering-to-medium-to-large-sized-goods"}
                />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={
                    "page.home.catering-to-medium-to-large-sized-goods-description"
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="body-wrapper-2">
        <Container>
          <center>
            <h1>
              <FormattedMessage id="page.home.for-carrier-advantages" />
            </h1>
            <div className="description">
              <FormattedMessage
                id={"page.home.for-carrier-description-first"}
              />
              <p>
                <FormattedMessage
                  id={"page.home.for-carrier-description-second"}
                />
              </p>
            </div>
          </center>
          <Row className="body-wrapper-row">
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">01</span>
              <h2>
                <FormattedMessage
                  id={"page.home.carrier-endless-opportunities"}
                />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.carrier-endless-opportunities-description"}
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">02</span>
              <h2>
                <FormattedMessage
                  id={"page.home.carrier-reliable-guarantees"}
                />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.carrier-reliable-guarantees-description"}
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">03</span>
              <h2>
                <FormattedMessage id={"page.home.carrier-fast-payment"} />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.carrier-fast-payment-description"}
                />
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} className="box-item">
              <span className="block-title-num">04</span>
              <h2>
                <FormattedMessage
                  id={"page.home.carrier-simplified-operations"}
                />
              </h2>
              <div className="box-message">
                <FormattedMessage
                  id={"page.home.carrier-simplified-operations-description"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="body-wrapper-3 d-none d-md-block">
        <Container className="wrapper-3-container">
            <div className="wrapper-3-content">
            <Tab.Container id="switch-tabs-wrapper" defaultActiveKey="first">
            <Row className="mobile-reverse">
              <Col xs={12} md={6} lg={6} className="phone-image-container">
                <Image className="img-phone" src={picPhone} />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Nav variant="pills" className="nva-tabs-custom">
                  <Nav.Item className="col-6">
                    <Nav.Link eventKey="first">
                      <div className="nva-tabs-custom-box">
                        <h1>
                          <FormattedMessage id={"nav.shipper"} />
                        </h1>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-6">
                    <Nav.Link eventKey="second">
                      <div className="nva-tabs-custom-box">
                        <h1>
                          <FormattedMessage id={"nav.carrier"} />
                        </h1>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row>
                      <Col>
                        <div className="left-box-top">
                          <span
                            className="italic-text-logo"
                            style={{ lineHeight: "50px", marginBottom: "26px" }}
                          >
                            <Image src={italicTextLogo} fluid />
                          </span>
                          <h1 className="sub-title">App</h1>
                        </div>
                        <div className="left-box-bottom">
                          <div className="join-description">
                            <FormattedMessage
                              id={"page.home.carrier-join-description"}
                            />
                          </div>
                          <ul className="join-ul">
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage id="page.home.place-order-online" /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.real-time-order-tracking"}
                              /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.query-order-history"}
                              /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.credit-card-payment"}
                              /></span>
                            </li>
                          </ul>
                          <ButtonGroup className="w-100 download-button-group">
                            <Button
                              variant="secondary"
                              className={"btn-download"}
                              style={{ width: "calc(100% - 60px)" }}
                              onClick={() => {
                                setShowShipperQRDownloadToast(true);
                              }}
                            >
                              <FormattedMessage id={"btn.download"} />
                            </Button>
                            <Button
                              variant="secondary"
                              style={{ width: "60px" }}
                              className="btn-qr"
                              onClick={() => {
                                setShowShipperQRDownloadToast(true);
                              }}
                            >
                              <Image src={iconQRCode} className={"qr-img"} />
                            </Button>
                          </ButtonGroup>
                          <Toast
                            autohide={true}
                            className="download-toast"
                            show={showShipperQRDownloadToast}
                            onClose={() => {
                                setShowShipperQRDownloadToast(false);
                            }}
                          >
                            <Toast.Header className="justify-content-center">
                              <Image
                                src={QrCodeImg}
                                className="rounded me-2"
                                alt=""
                                fluid
                              />
                            </Toast.Header>
                          </Toast>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row>
                      <Col>
                        <div className="left-box-top">
                          <span
                            className="italic-text-logo"
                            style={{ lineHeight: "50px", marginBottom: "26px" }}
                          >
                            <Image src={italicTextLogo} fluid />
                          </span>
                          <h1 className="sub-title">App</h1>
                        </div>
                        <div className="left-box-bottom">
                          <div className="join-description">
                            <FormattedMessage
                              id={"page.home.carrier-join-description"}
                            />
                          </div>
                          <ul className="join-ul">
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage id="page.home.place-order-online" /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.real-time-order-tracking"}
                              /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.query-order-history"}
                              /></span>
                            </li>
                            <li className="d-flex align-items-center">
                              <span className="text_45">
                                <Image src={iconCheck} fluid />
                              </span>
                              <span className="feature-text"><FormattedMessage
                                id={"page.home.credit-card-payment"}
                              /></span>
                            </li>
                          </ul>
                          <ButtonGroup className="w-100 download-button-group">
                            <Button
                              variant="secondary"
                              className={"btn-download"}
                              style={{ width: "calc(100% - 60px)" }}
                              onClick={() => {
                                setShowCarrierQRDownloadToast(true);
                              }}
                            >
                              <FormattedMessage id={"btn.download"} />
                            </Button>
                            <Button
                              variant="secondary"
                              style={{ width: "60px" }}
                              className="btn-qr"
                              onClick={() => {
                                setShowCarrierQRDownloadToast(true);
                              }}
                            >
                              <Image src={iconQRCode} className={"qr-img"} />
                            </Button>
                          </ButtonGroup>
                          <Toast
                            autohide={true}
                            className="download-toast"
                            show={showCarrierQRDownloadToast}
                            onClose={() => {
                                setShowCarrierQRDownloadToast(false);
                            }}
                          >
                            <Toast.Header className="justify-content-center">
                              <Image
                                src={QrCodeImg}
                                className="rounded me-2"
                                alt=""
                                fluid
                              />
                            </Toast.Header>
                          </Toast>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
            </div>
        </Container>
      </div>
      <div className="body-wrapper-4">
        <div className="bg-div">
          <Container>
            <center>
              <Image src={iconWish} className="icon-wish" />
              <h1>
                <FormattedMessage id={"page.home.company-vision"} />
              </h1>
              <div className="white-line"></div>
              <p className="wish-description">
                <FormattedMessage id={"page.home.company-vision-description"} />
              </p>
            </center>
          </Container>
        </div>
      </div>
    </div>
  );
}
