import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import TwitterIcon from "@/assets/ic_twitter@2x.png";
import FacebookIcon from "@/assets/ic_facebook@2x.png";
import IcIcon from "@/assets/ic_ins@2x.png";
import { FormattedMessage } from "react-intl";
import "./index.css";

// https://firebase.google.com/docs/web/setup#available-libraries
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

import italicTextLogo from "@/assets/italic_text_logo.png";

// CSS styles
const styles = {
  footer: {
    backgroundColor: "#686868",
    color: "#FFFFFF",
    paddingBottom: "80px"
  },
  navbarBrand: {
    color: "#FFFFFF"
  },
  footerNavLink: {
    color: "#FFFFFF !important"
  },
  footerNavbar: {
    paddingBottom: "45px",
    paddingTop: window.innerWidth < 1000 ? "40px" : "90px",
    backgroundColor: "#686868 !important"
  },
  footerBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    borderTop: "1px solid rgba(255, 255, 255, .2)",
    paddingTop: "52px"
  },
  pFollow: {
    margin: 0,
    color: "#FFFFFF"
  },
  italicTextLogo: {
    width: "215px",
    height: "37px"
  },
  footerContainer: {
    maxWidth: "1580px"
  },
  footerBottomContainer: {
    maxWidth: "1580px"
  },
  footerBottomUl: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginLeft: "16px"
  },
  navLink: {
    padding: "0 8px",
    listStyleType: "none",
    color: "#FFFFFF"
  },
  footerBottomRight: {
    display: "none",
    flexBasis: "auto",
    flexWrap: "inherit",
    alignItems: "center",
    justifyContent: "space-between"
  },
  btnRegister: {
    background: "#000000",
    borderRadius: "15px",
    color: "#FFFFFF"
  },
  socialIcon: {
    width: "48px",
    height: "48px"
  },
  navbarToggler: {
    backgroundColor: "#F68817",
    color: "#FFFFFF"
  },
  footerLogoTitle: {
    fontSize: "32px",
    fontFamily: "SFPro-BoldItalic, SFPro",
    fontWeight: "normal",
    color: "#FFFFFF"
  },
  mediaQuery: {
    footerNavbar: {
      padding: "20px 0"
    },
    footerBottom: {
      padding: "20px 0"
    },
    footerBottomContainer: {
      marginBottom: "30px"
    }
  }
};

export default function () {
  // // Initialize Firebase
  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: window.location.pathname });
  }, []);

  return (
    <footer style={styles.footer}>
      <Navbar expand="lg" style={styles.footerNavbar} expanded={window.innerWidth < 1000}>
        <Container style={styles.footerContainer}>
          <Navbar.Brand href="#home" style={styles.footerLogoTitle}>
            <Image
              style={{ lineHeight: "48px", ...styles.italicTextLogo }}
              src={italicTextLogo}
              className="italic-text-logo"
            />
          </Navbar.Brand>
          <Navbar.Collapse
            id="footer-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="nav-link-wrapper">
              <Nav.Link href="/" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.home"} />
              </Nav.Link>
              <Nav.Link href="/privacy-policy" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.privacy"} />
              </Nav.Link>
              <Nav.Link href="/transaction-and-security" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.safety-of-transaction"} />
              </Nav.Link>
              <Nav.Link href="/shipping-policies" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.transportation-strategy"} />
              </Nav.Link>
              <Nav.Link href="/terms-of-service" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.terms-of-service"} />
              </Nav.Link>
              <Nav.Link href="/contact-us" style={styles.footerNavLink}>
                <FormattedMessage id={"nav.link.contact-us"} />
              </Nav.Link>
              <Nav.Link href="/announcement" style={styles.footerNavLink}>Announcement</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container style={styles.footerBottomContainer}>
        <div style={styles.footerBottom}>
          <div style={{color: "#FFFFFF"}}>© 2025 ExtraSpace. All rights reserved.</div>
        </div>
      </Container>
    </footer>
  );
}
