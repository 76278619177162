export default {
    "page.home.slogan-looking-for-a-transport-solution": "寻找运输解决方案？",
    "page.home.choose": "就选",
    "page.home.extra-space": "ExtraSpace",
    "page.home.btn.download-app": "下载App",
    "page.home.btn.free-quote": "免费询价",
    "page.home.btn.post-router": "发布线路",
    "page.home.for-shipper-advantages": "对于用户，ExtraSpace 的优势",
    "page.home.for-carrier-advantages": "加入ExtraSpace，您将享受到",

    // "page.home.registered-user": "已注册用户",
    // "page.home.registered-carrier": "已注册司机",
    // "page.home.completed-order": "已完成运单",
    "page.home.advantages-of-extra-space": "ExtraSpace的优势",
    "page.home.for-shipper-description-first": "您正在寻找一个可信赖、高效、价格公道的运输平台吗？欢迎来到ExtraSpace！",
    "page.home.for-shipper-description-second": "ExtraSpace是一个特别为满足中大型货物运输需求而设计的平台。我们致力于简化整个运输过程，使之成为您的信赖之选。",
    "page.home.trustworthy-choice": "信赖之选",
    "page.home.trustworthy-choice-description": "我们的平台汇集了众多专业的运输公司，所有的订单都经过严格的审核和跟踪，以确保您的货物安全抵达。",
    "page.home.efficiency-and-simplicity": "简洁高效",
    "page.home.efficiency-and-simplicity-description": "通过我们的app，您只需几个简单的步骤就能快速地下单。只要输入货物信息，再点击确认，就完成了！",
    "page.home.fair-pricing": "价格公道",
    "page.home.fair-pricing-description": "在ExtraSpace，您不需要为运输中大型货物而烦恼。我们提供了竞争力的价格，让您的运输需求不再负担沉重。",
    "page.home.catering-to-medium-to-large-sized-goods": "适合中大型货物",
    "page.home.catering-to-medium-to-large-sized-goods-description": "无论是家具，设备，还是其他任何中大型货物，ExtraSpace都能轻松应对。我们的平台致力于让每一个货物都找到合适的运输公司。",
    "page.home.carrier-join-extra-space": "ExtraSpace诚邀您的加盟，释放您货车的全部潜力！",
    "page.home.for-carrier-description-first": "您是一家寻找更多商机的运输公司吗？您是否曾经困扰过半满的货车浪费了运输空间？现在，ExtraSpace为您提供了解决方案！",
    "page.home.for-carrier-description-second": "ExtraSpace是一个专注于中大型货物运输的在线平台。我们鼓励运输公司充分利用他们的货车空间，无论这个空间是一辆完全空的货车，还是只有少量的剩余空间，我们都可以帮助您找到合适的订单，将这些空间转化为额外的利润。",
    "page.home.carrier-endless-opportunities": "无尽商机",
    "page.home.carrier-endless-opportunities-description": "我们的平台上有大量用户需要运输中大型货物。无论您的车辆剩余多少空间，我们都能匹配到合适的订单，让您的车辆始终保持高效运转。",
    "page.home.carrier-reliable-guarantees": "信誉保障",
    "page.home.carrier-reliable-guarantees-description": "我们保证您在每次运输中都能得到及时、公正的报酬。",
    "page.home.carrier-fast-payment": "快速支付",
    "page.home.carrier-fast-payment-description": "我们的支付系统完全自动化。订单一旦完成，您的报酬将在3天内自动支付到您的账户中。",
    "page.home.carrier-simplified-operations": "简洁操作",
    "page.home.carrier-simplified-operations-description": "通过我们的App，您可以轻松接收和管理订单，让运输变得更简单，更方便。",
    "page.home.carrier-join-description": "加入ExtraSpace，充分利用您的运输资源，释放您货车的全部潜力，为您创造更多收益！我们期待您的加入，让我们一起打造更智能、更高效的运输未来！",
    "page.home.carrier-join": "加入 ExtraSpace",
    "page.home.carrier-join-subtitle": "释放您货车的全部潜力，为您创造更多收益！",
    "page.home.carrier-register-description": "加入ExtraSpace，充分利用您的运输资源，释放您货车的全部潜力，为您创造更多收益！我们期待您的加入，让我们一起打造更智能、更高效的运输未来！",
    "page.home.btn.carrier-join": "立即加入",
    "page.home.place-order-online": "在线发布订单",
    "page.home.real-time-order-tracking": "订单实时跟踪",
    "page.home.query-order-history": "历史订单查询",
    "page.home.credit-card-payment": "信用卡支付",
    "page.home.company-vision": "公司愿景",
    "page.home.company-vision-description": "ExtraSpace致力于推动运输行业的未来走向高效与创新，期望实现一个每一寸货物空间都被充分利用的世界，确保持续的增长并为运输商和发货人都最大化机会。"
}