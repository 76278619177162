import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Toast from "react-bootstrap/Toast";
import { FormattedMessage } from "react-intl";

// Import assets
import italicTextLogo from "@/assets/italic_text_logo.png";
import iconQRCode from "@/assets/ic_qrcode@2x.png";
import QrCodeImg from "@/assets/download-qrcode.png";

// Component styles
const styles = {
    slogan_wrapper: {
        padding: "60px 0",
    },
    slogan_title: {
        fontFamily: "PingFangSC-Light, PingFang SC",
        fontWeight: 300,
        fontSize: "40px",
        lineHeight: "56px",
        color: "#000000",
    },
    italic_text_logo: {
        width: "215px",
        height: "37px",
        lineHeight: "56px",
        marginLeft: "15px",
    },
    btn_wrapper: {
        marginTop: "39px",
        minWidth: "220px",
        display: "flex",
    },
    btn_download: {
        height: "60px",
        fontSize: "18px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
        fontWeight: 600,
        background: "linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%)",
        borderRadius: "8px 0px 0px 8px",
        border: "none",
        padding: 0,
        color: "#ffffff",
    },
    btn_qr: {
        height: "60px",
        fontSize: "18px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
        fontWeight: 600,
        background: "linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%)",
        borderRadius: "0px 8px 8px 0px",
        border: "none",
        padding: 0,
        color: "#ffffff",
    },
    btn_free_quote: {
        padding: "0 40px",
        marginLeft: "24px",
        fontWeight: 600,
        color: "#f68817",
        background: "#ffffff",
        border: "2px solid #f68817",
        borderRadius: "8px",
        height: "60px",
        fontSize: "18px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
    },
    download_toast: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxWidth: "280px",
        zIndex: 1050,
    },
    toast_header: {
        padding: "15px",
        justifyContent: "center",
    },
    toast_image: {
        width: "100%",
        height: "auto",
    },
};


// PC version of the component
function PChmain( props ) {
    const [showTopQRDownloadToast, setShowTopQRDownloadToast] = useState(false);
    let img = ''
    if (props.imgType === 'docker') {
        img = require('@/assets/quote_top_bg.png')
    } else {
        img = ' '
    }
    // console.log(img)
    return (
        <div style={{backgroundImage: `url(${img})`, backgroundSize: '100% 100%', backgroundPosition: 'center', display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
            <div style={styles.slogan_wrapper}>
                <h2 style={styles.slogan_title} >
                    <FormattedMessage
                        id={"page.home.slogan-looking-for-a-transport-solution"}
                    />
                </h2>
                <div style={styles.slogan_title}>
                    <span>
                        <FormattedMessage id={"page.home.choose"} />
                    </span>
                    <span>
                        <Image
                            src={italicTextLogo}
                            style={styles.italic_text_logo}
                        />
                    </span>
                </div>

                <div style={styles.btn_wrapper} >
                    <ButtonGroup className="download-button-group">     
                        <Button
                            variant="secondary"
                            style={{...styles.btn_download, minWidth: "160px"}}
                            onClick={() => {
                                setShowTopQRDownloadToast(true);
                            }}
                        >
                            <FormattedMessage id={"btn.app.download"} />
                        </Button>
                        <Button
                            variant="secondary"
                            style={{...styles.btn_qr, minWidth: "60px"}}
                            onClick={() => {
                                setShowTopQRDownloadToast(true);
                            }}
                        >
                            <Image src={iconQRCode} style={{width: "24px", height: "24px"}}/>
                        </Button>
                    </ButtonGroup>

                    <Button 
                        variant="secondary" 
                        style={styles.btn_free_quote}
                        onClick={() => {
                            window.location.href = '/quote#quote'
                        }}
                    >
                        <FormattedMessage id={"page.home.btn.free-quote"} defaultMessage={"免费询价"}/>
                    </Button>

                    <Toast
                        autohide={true}
                        style={styles.download_toast}
                        show={showTopQRDownloadToast}
                        onClose={() => {
                            setShowTopQRDownloadToast(false);
                        }}
                    >
                        <Toast.Header style={styles.toast_header}>
                            <Image
                                src={QrCodeImg}
                                style={styles.toast_image}
                                alt=""
                                fluid
                            />
                        </Toast.Header>
                    </Toast>
                </div>
            </div>
        </div>
    );
}


// Mobile styles
const mobileStyles = {
    slogan_wrapper: {
        // position: "relative",
        paddingTop: "24px",
    },
    slogan_title: {
        fontFamily: "PingFangSC-Light, PingFang SC",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "100%",
        letterSpacing: "0px",
        color: "#000000",
        textAlign: "start",
    },
    slogan_title_2: {
        marginTop: "12px",
        fontFamily: "PingFangSC-Light, PingFang SC",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "100%",
        letterSpacing: "0px",
        color: "#000000",
        textAlign: "center",
    },
    italic_text_logo: {
        width: "140px",
        height: "26px",
        lineHeight: "36px",
        marginLeft: "8px",
    },
    btn_wrapper: {
        marginTop: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
    },
    download_button_group: {
        width: "100%",
        maxWidth: "220px",
    },
    btn_download: {
        height: "50px",
        fontSize: "16px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
        fontWeight: 600,
        background: "linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%)",
        borderRadius: "8px",
        border: "none",
        padding: 0,
        color: "#ffffff",
        lineHeight: "50px",
        width: "100%",
        maxWidth: "220px",
    },
    btn_qr: {
        height: "50px",
        fontSize: "16px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
        fontWeight: 600,
        background: "linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%)",
        borderRadius: "0px 8px 8px 0px",
        border: "none",
        padding: 0,
        color: "#ffffff",
        lineHeight: "50px",
    },
    btn_free_quote: {
        padding: "0 20px",
        fontWeight: 600,
        color: "#f68817",
        background: "#ffffff",
        border: "2px solid #f68817",
        borderRadius: "8px",
        height: "50px",
        fontSize: "16px",
        fontFamily: "PingFangSC-Semibold, PingFang SC",
        width: "100%",
        maxWidth: "220px",
        marginLeft: 0,
        lineHeight: "50px",
    },
};


// Mobile version of the component
function Mobilehmain(props) {
    const [showTopQRDownloadToast, setShowTopQRDownloadToast] = useState(false);
    const [img, setImg] = useState(null);
    const [imgWidth, setImgWidth] = useState('100%');
    useEffect(() => {
        console.log('props.imgType', props.imgType)
        if (props.imgType === 'docker') {
            setImg(require('@/assets/quote_bg_2.png'));
        } else if (props.imgType === 'truck') {
            setImgWidth('75%');
            setImg(require('@/assets/pic_truck@2x.png'));
        } else {
            setImg(null);
        }

    }, []);

    return (
        <>
            <div style={mobileStyles.slogan_wrapper} className="slogan-wrapper">
                <h2 style={mobileStyles.slogan_title} >
                    <FormattedMessage
                        id={"page.home.slogan-looking-for-a-transport-solution"}
                    />
                </h2>
                <div style={mobileStyles.slogan_title_2}>    
                    <span>
                        <FormattedMessage id={"page.home.choose"} />
                    </span>
                    <span>
                        <Image
                            src={italicTextLogo}
                            style={mobileStyles.italic_text_logo}
                        />
                    </span>
                </div>

                <div style={{margin: '12px auto'}} >
                    <Image src={img} style={{width: imgWidth, height: 'auto'}} />
                </div>

                <div style={mobileStyles.btn_wrapper} className="btn-wrapper">

                    <Button
                            variant="secondary"
                            style={mobileStyles.btn_download}
                            className={"btn-download"}
                            onClick={() => {
                                setShowTopQRDownloadToast(true);
                            }}
                        >
                            <FormattedMessage id={"btn.app.download"} />
                    </Button>

                    <Button 
                        variant="secondary" 
                        style={mobileStyles.btn_free_quote}
                        className="btn-free-quote" 
                        onClick={() => {
                            window.location.href = '/quote#quote'
                        }}
                    >
                        <FormattedMessage id={"page.home.btn.free-quote"} defaultMessage={"免费询价"}/>
                    </Button>

                    <Toast
                        autohide={true}
                        style={styles.download_toast}
                        className="download-toast"
                        show={showTopQRDownloadToast}
                        onClose={() => {
                            setShowTopQRDownloadToast(false);
                        }}
                    >
                        <Toast.Header style={styles.toast_header}>
                            <Image
                                src={QrCodeImg}
                                style={styles.toast_image}
                                className="rounded me-2"
                                alt=""
                                fluid
                            />
                        </Toast.Header>
                    </Toast>
                </div>
            </div>
        </>
    );
}

// Main component that decides which version to render
export default function Hmain(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return isMobile ? <Mobilehmain imgType={props.imgType} /> : <PChmain imgType={props.imgType} />;
}