import React, { useState, useEffect, useContext } from "react";
import { getAnalytics, setUserId } from "firebase/analytics";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";
import {
  ConfigProvider,
  Tabs,
  Form,
  Input,
  Modal,
  Button,
  message,
  Carousel,
} from "antd";
import QuoteForm from "./components/quote";
import PlaceOrder from "./components/placeOrder";
import theme from "@/AntDesignConfig";

import Hmain from "@/components/h";

import italicTextLogo from "@/assets/italic_text_logo.png";

import "./index.css";
import { createStyles } from "antd-style";
import CardForm from "./components/CardForm";

import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";
import qs from "qs";
import OrderSuccess from "./components/orderSuccess";
import { useSearchParams } from "react-router-dom";

import OrderList from "./components/OrderList";

import { glogEvent } from "@/components/firebaseConfig";

import { AppContext } from "@/Context";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Toast from "react-bootstrap/Toast";

import iconQRCode from "@/assets/ic_qrcode@2x.png";

import QrCodeImg from "@/assets/download-qrcode.png";

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

const useCountDown = (s) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};

export default function Quote() {
  const { onShipperLogin } = useContext(AppContext);

  const [searchParams] = useSearchParams();
  const { styles } = useStyle();

  const [messageApi, contextHolder] = message.useMessage();

  const [finalOrderData, setFinalOrderData] = useState(null);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginForm] = Form.useForm();
  const [smsCount, setSMSCount] = useCountDown(0);
  const [isSMSLoading, setIsSMSLoading] = useState(false);

  const [shipperToken, setShipperToken] = useState(null);
  const [tab, setTab] = useState("1");

  const [tabBadge, setTabBadge] = useState({});

  const [orderSuccess, setOrderSuccess] = useState(false);

  const [showTopQRDownloadToast, setShowTopQRDownloadToast] = useState(false);
  const [showShipperQRDownloadToast, setShowShipperQRDownloadToast] = useState(false);
  const [showCarrierQRDownloadToast, setShowCarrierQRDownloadToast] = useState(false);

  // 订单数据 未支付
  const [orderId, setOrderId] = useState(undefined);

  const [quoteData, setQuoteData] = useState({});

  const [orderList, setOrderList] = useState([]);
  const [orderListLoading, setOrderListLoading] = useState(false);
  
  // Check if we're on mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // get token form local storage using key : "shipper_token"
    let token = localStorage.getItem("shipper_token");
    if (token) {
      setShipperToken(token);
      getTabBadge().then((badge) => {
        setTabBadge(badge);
      });
    } else {
      if (searchParams.get("modal") === "1") {
        setTimeout(() => {
          setIsLoginModalOpen(true);
          // remove modal param
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        }, 1000);
      }
    }
  }, []);

  const goToOrderList = () => {
    setTab("0");
  };

  const fetchOrderList = async (tab) => {
    setOrderListLoading(true);

    // param tab 0-我的发布，1-运单消息，2-运输中，3-已完成
    let payload = {
      tab: tab,
    };
    let params = {
      page_num: 1,
      page_size: 100,
      tab: tab,
    };
    let token = localStorage.getItem("shipper_token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(
      `${BASE_API_URL}/api/v1/app/shipper/orders?${qs.stringify(params)}`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
      }
    );
    if (response.status === 401) {
      setIsLoginModalOpen(true);
      setOrderListLoading(false);
      return;
    }
    if (!response.ok) {
      setOrderListLoading(false);
      return;
    }
    const text = await response.text();
    const result = JSONBig({ storeAsString: true }).parse(text);
    setOrderList(result.data.data);
    setOrderListLoading(false);
  };

  const deleteOrder = async (orderId) => {
    console.log("deleteOrder", orderId);
    let token = localStorage.getItem("shipper_token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(
      `${BASE_API_URL}/api/v1/app/shipper/orders/${orderId}`,
      {
        method: "DELETE",
        headers: headers,
      }
    );
    if (response.status === 401) {
      setIsLoginModalOpen(true);
      return;
    }
    if (!response.ok) {
      messageApi.error("Delete order failed");
      return;
    }
    messageApi.success("订单删除成功");
    await fetchOrderList(tab);
  };

  useEffect(() => {
    if (tab !== "1") {
      fetchOrderList(tab);
    }
  }, [tab]);

  const goToQuote = () => {
    setPlaceOrderShow(false);
    setOrderSuccess(false);
    setOrderId(undefined);
    setTab("1");
  };

  const login = async (params) => {
    const payload = {
      tel: params.phone,
      country_code: "+1",
      code: params.password,
      role: 0,

    };
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/app/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.code !== 0) {
        console.error(`Login failed: ${result.errmsg}`);
        messageApi.error(result.errmsg);
        return null;
      }
      const { access_token, user_id, current_role, is_verify } = result.data;
      const analytics = getAnalytics();
      setUserId(analytics, params.phone);
      setShipperToken(access_token)
      onShipperLogin(access_token, params.phone);

      setIsLoginModalOpen(false);

      return access_token;
    } catch (error) {
      console.error("Login error:", error);
      messageApi.error("Login failed. Please try again.");
      return null;
    }
  };

  const fetchCode = async (params) => {
    try {
      setIsSMSLoading(true);
      const response = await fetch(`${BASE_API_URL}/api/v1/app/sendsms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (response.status !== 200) {
        console.log(`request login send code api failed:${response.status}`);
        messageApi.error("网络错误");
        return;
      }
      setSMSCount(60);
    } catch (error) {
      console.log(`request login api error: ${error}`);
      messageApi.error("网络错误");
    } finally {
      setIsSMSLoading(false);
    }
  };

  const getTabBadge = async () => {
    try {
      const token = localStorage.getItem("shipper_token");
      if (!token) {
        return;
      }
      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/unread/message/badge`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        localStorage.removeItem("shipper_token");
        setShipperToken(null);
        return;
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);
      console.log("getTabBadge result", result);
    } catch (error) {
      console.log(`get badge api error: ${error.message}`);
    }
  };
  const placeOrder = async (data) => {
    try {
      // Google analytics custom event log
      glogEvent("shipper_submit", data);

      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/shipper/order/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${shipperToken}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);
      console.log("place order result", result);
      if (result.code !== 0) {
        console.log(`place order api failed:${JSON.stringify(result)}`);
        messageApi.error(result.errmsg);
        return;
      }
      console.log("place order result", result);
      setOrderId(result.data.order_id);
      setFinalOrderData(data);
    } catch (error) {
      console.log(`place order api error: ${error.message}`);
      messageApi.error("网络错误");
    }
  };

  const getOrderDetail = async (orderId) => {
    // 订单详情
    // /api/v1/app/shipper/orders/{order_id}
    const response = await fetch(
      `${BASE_API_URL}/api/v1/app/shipper/orders/${orderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${shipperToken}`,
        },
      }
    );
    const text = await response.text();
    const result = JSONBig({ storeAsString: true }).parse(text);
    console.log("getOrderDetail result", result);
    setFinalOrderData(result.data);
  };

  const continuePay = (orderId) => {
    console.log("continuePay", orderId);
    // 订单列表 未支付订单 支付
    setOrderId(orderId);
    getOrderDetail(orderId);
    setOrderSuccess(false);
    setTab("1");
  };
  

  // payment
  const pay = async (data) => {
    console.log("pay", data);
    let payload = {
      order_id: orderId,
      card_id: data.card_id,
      billing_address: data.billing_address
        ? data.billing_address
        : {
            name: finalOrderData.pickup_address.name,
            address_line1: finalOrderData.pickup_address.address_line1,
            address_line2: finalOrderData.pickup_address.address_line2,
            city: finalOrderData.pickup_address.city,
            state: finalOrderData.pickup_address.state,
            zipcode: finalOrderData.pickup_address.zipcode,
          },
    };

    // Google analytics custom event log
    glogEvent("shipper_pay", payload);

    try {
      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/shipper/order/payment/v2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${shipperToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);

      if (result.code !== 0) {
        console.log(`payment api failed: ${JSON.stringify(result)}`);
        messageApi.error(result.errmsg);
        return;
      }
      console.log("payment result", result);
      setOrderSuccess(true);
    } catch (error) {
      console.log(`payment api error: ${error.message}`);
      messageApi.error("网络错误");
    }
  };

  const onTabChange = (key) => {
    if (["0", "2", "3"].includes(key)) {
      if (!shipperToken) {
        setIsLoginModalOpen(true);
        return;
      }
    }
    setTab(key);
  };

  const tabItems = [
    {
      key: "1",
      label: useIntl().formatMessage({
        id: "page.quote.tab.offer",
        description: "在线下单",
      }),
    },
    {
      key: "0",
      label: useIntl().formatMessage({
        id: "page.quote.tab.published",
        description: "已发布",
      }),
    },
    {
      key: "2",
      label: useIntl().formatMessage({
        id: "page.quote.tab.in.transit",
        description: "运输中",
      }),
    },
    {
      key: "3",
      label: useIntl().formatMessage({
        id: "page.quote.tab.done",
        description: "已完成",
      }),
    },
  ];

  const [placeOrderShow, setPlaceOrderShow] = useState(false);
  const [isPallet, setIsPallet] = useState(false);
  const gotoPlaceOrder = (gotQuoteData) => {
    setQuoteData(gotQuoteData);
    setIsPallet(gotQuoteData.is_pallet);
    console.log("shipperToken", shipperToken);
    if (!shipperToken) {
      setIsLoginModalOpen(true);
      return;
    }
    setPlaceOrderShow(true);
  };

  // Render the features section as a Carousel on mobile or regular grid on desktop
  const renderFeatures = () => {
    const featureItems = [
      {
        number: "01",
        title: useIntl().formatMessage({
          id: "page.quote.top.title.1",
          description: "信赖之选",
        }),
        content: useIntl().formatMessage({
          id: "page.quote.top.content.1",
          description:
            "我们的平台汇集了众多专业的运输公司，所有的订单都经过严格的审核和跟踪，以确保您的货物安全抵达。",
        }),
      },
      {
        number: "02",
        title: useIntl().formatMessage({
          id: "page.quote.top.title.2",
          description: "简洁高效",
        }),
        content: useIntl().formatMessage({
          id: "page.quote.top.content.2",
          description:
            "通过我们的app，您只需几个简单的步骤就能快速地下单。只要输入货物信息，再点击确认，就完成了！",
        }),
      },
      {
        number: "03",
        title: useIntl().formatMessage({
          id: "page.quote.top.title.3",
          description: "价格公道",
        }),
        content: useIntl().formatMessage({
          id: "page.quote.top.content.3",
          description:
            "在ExtraSpace，您不需要为运输中大型货物而烦恼。我们提供了竞争力的价格，让您的运输需求不再负担沉重。",
        }),
      },
      {
        number: "04",
        title: useIntl().formatMessage({
          id: "page.quote.top.title.4",
          description: "适合中大型货物",
        }),
        content: useIntl().formatMessage({
          id: "page.quote.top.content.4",
          description:
            "无论是家具，设备，还是其他中大型货物，我们都能轻松应对。我们的平台致力于让每件货物都找到合适的运输公司。",
        }),
      },
    ];

    if (isMobile) {
      return (
        <div style={{marginTop: "60px", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          {featureItems.map((item, index) => (
            <div key={index} style={{width: "47%", marginBottom: "20px"}}>
              <div className="" style={{ gap: "12px" }}>
                <p className="" style={{fontFamily: "PingFang SC", fontWeight: 400, fontSize: "17px", lineHeight: "100%", letterSpacing: "0px"}}>{item.title}</p>
                <p className="" style={{fontFamily: "PingFang SC", fontWeight: 400, fontSize: "12px", lineHeight: "135%", letterSpacing: "0.1px", color: "var(--Dark, #686868)"}}>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="quote-wrapper-row">
          {featureItems.map((item, index) => (
            <div className="quote-item-box" key={index}>
              <p className="number">{item.number}</p>
              <p className="title">{item.title}</p>
              <p className="content">{item.content}</p>
            </div>
          ))}
        </div>
      );
    }
  };

  const bg_img = require('@/assets/quote_top_bg.png')


  return (
    <ConfigProvider
      theme={theme}
      button={{
        className: styles.linearGradientButton,
      }}
    >
      {contextHolder}
      <div className="quote-wrapper">
        <div className="quote-wrapper-0 container">
          {/* <div className="container"> */}
            {/* <div className="bg-extra-space"> */}
              <Hmain imgType={'docker'} />
            {/* </div> */}
          {/* </div> */}
        </div>
        <div className="quote-wrapper-1 container">
          <Container>
            {renderFeatures()}
          </Container>
        </div>
        <div className="quote-wrapper-2 container">
          <Tabs
            // id="quote"
            activeKey={tab}
            items={tabItems}
            onChange={onTabChange}
            size={isMobile ? "small" : "default"}
            tabPosition="top"
            centered={isMobile}
            tabBarGutter={isMobile ? 0 : 16}
            // style={{ 
            //   width: '100%',
            //   overflowX: 'hidden'
            // }}
            // tabBarStyle={{
            //   display: 'flex',
            //   flexWrap: 'wrap',
            //   justifyContent: 'center'
            // }}
          />
        </div>
        {tab === "1" &&
          (orderSuccess ? (
            <OrderSuccess
              orderId={orderId}
              goToOrderList={goToOrderList}
              goToQuote={goToQuote}
            />
          ) : orderId === undefined ? (
            <div className="quote-wrapper-3">
              {!placeOrderShow ? (
                <QuoteForm nextStep={gotoPlaceOrder} />
              ) : (
              
                <PlaceOrder
                  quoteData={quoteData}
                  goToQuote={goToQuote}
                  placeOrder={placeOrder}
                />
              )}
            </div>
          ) : (
            <div className="quote-wrapper-3">
              <CardForm pay={pay} />
            </div>
          ))}
        {["0", "2", "3"].includes(tab) && (
          <div className="quote-wrapper-3">
            <OrderList
              loading={orderListLoading}
              orderList={orderList}
              goToQuote={goToQuote}
              deleteOrder={deleteOrder}
              continuePay={continuePay}
            />
          </div>
        )}
      </div>
      <Modal
        open={isLoginModalOpen}
        onCancel={() => {
          setIsLoginModalOpen(false);
        }}
        centered
        footer={null}
        width={720}
      >
        <div className="login-form-container">
          <Form
            name="login"
            layout="vertical"
            variant="filled"
            requiredMark={false}
            form={loginForm}
            onFinish={login}
          >
            <Image
              src={italicTextLogo}
              style={{
                lineHeight: "56px",
                marginBottom: 32,
                marginLeft: 42.5,
                marginRight: 42.5,
              }}
              className={"italic-text-logo"}
            />
            <Form.Item
              style={{ width: 300 }}
              name="phone"
              label={useIntl().formatMessage({
                id: "page.quote.login.form.label.phone",
                description: "电话号码",
              })}
              rules={[
                {
                  required: true,
                  message: useIntl().formatMessage({
                    id: "page.quote.login.form.error.info.phone",
                    description: "电话号码 异常提示",
                  }),
                },
              ]}
            >
              <Input
                placeholder={useIntl().formatMessage({
                  id: "page.quote.login.form.placeholder.phone",
                  description: "电话号码 输入占位",
                })}
                size="small"
                suffix={
                  <Button
                    type="link"
                    onClick={() => {
                      loginForm.validateFields(["phone"]).then((values) => {
                        console.log(values);
                        if (!isSMSLoading && smsCount === 0) {
                          fetchCode({ country_code: "+1", tel: values.phone });
                        }
                      });
                    }}
                    loading={isSMSLoading}
                    disabled={smsCount !== 0}
                  >
                    {smsCount > 0
                      ? `${smsCount}s`
                      : useIntl().formatMessage({
                          id: "page.quote.login.form.button.code",
                          description: "发送验证码 按钮 文案",
                        })}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={useIntl().formatMessage({
                id: "page.quote.login.form.label.code",
                description: "验证码",
              })}
              rules={[
                {
                  required: true,
                  message: useIntl().formatMessage({
                    id: "page.quote.login.form.error.info.code",
                    description: "验证码 异常提示",
                  }),
                },
              ]}
            >
              <Input
                placeholder={useIntl().formatMessage({
                  id: "page.quote.login.form.placeholder.code",
                  description: "电话号码 输入占位",
                })}
                size="small"
                suffix={<div style={{ width: 1, height: 32 }}></div>}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size="large">
                {useIntl().formatMessage({
                  id: "page.quote.login.form.button.login",
                  description: "登录 按钮 文案",
                })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
