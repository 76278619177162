import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FormattedMessage, useIntl } from "react-intl";
import { AppContext, getLanguageIntlMessages, languageList } from "@/Context";
import router from "@/router";

import italicTextLogo from "@/assets/italic_text_logo_white.png";

// Inline CSS
const styles = {
  headerWrapper: {
    backgroundColor: "#ffffff"
  },
  headerNavbar: {
    background: "linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%)",
    opacity: 0.9
  },
  logoTitleImg: {
    width: "168px"
  },
  containerNavbar: {
    maxWidth: "1580px"
  },
  navLinkItem: {
    fontSize: "16px",
    fontFamily: "PingFangSC-Regular, PingFang SC",
    fontWeight: 400,
    color: "#000000",
    paddingRight: "30px",
    paddingLeft: "30px",
    textAlign: "center"
  },
  dropdownItem: {
    textAlign: "center"
  },
  customDropdownItemActive: {
    backgroundColor: "#ea7e30"
  },
  mobileNavLink: {
    padding: "10px 15px",
    // borderBottom: "1px solid rgba(0,0,0,0.1)"
  },
  mobileDropdown: {
    width: "100%",
    textAlign: "left"
  }
};

export default function Header() {
  const {
    phone,
    isLoggedIn,
    shipperPhone,
    isShipperLoggedIn,
    currentLanguage,
    setCurrentLanguage,
    onLogout,
    onShipperLogout,
  } = useContext(AppContext);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const changeLanguageEvent = (val) => {
    setCurrentLanguage(getLanguageIntlMessages(val));
  };

  const navLinkStyle = isMobile ? {...styles.navLinkItem, ...styles.mobileNavLink} : styles.navLinkItem;
  const dropdownStyle = isMobile ? {...styles.dropdownItem, ...styles.mobileDropdown} : styles.dropdownItem;

  return (
    <div style={styles.headerWrapper} className="Header-wrapper">
      <header className="App-header">
        <Navbar expand="lg" style={styles.headerNavbar} className="header-navbar">
          <Container className="container-navbar" style={window.innerWidth >= 1024 ? styles.containerNavbar : {}}>
            <Navbar.Brand href="/" className="logo-title">
              <Image src={italicTextLogo} style={styles.logoTitleImg} className={"logo-title-img"} />
            </Navbar.Brand>
            <Navbar.Toggle 
              aria-controls="basic-navbar-nav" 
              style={{ 
                backgroundColor: 'transparent', 
                border: 'none', 
                color: 'white' 
              }} 
              className="navbar-toggler"
              onFocus={(e) => e.target.style.boxShadow = 'none'}
              onClick={(e) => e.currentTarget.blur()}
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={isMobile ? "" : "justify-content-end"}
            >
              <Nav className={`nav-link-wrapper ${isMobile ? "w-100" : ""}`}>
                <Nav.Link href="/" style={navLinkStyle}>
                  <FormattedMessage id="nav.link.home" />
                </Nav.Link>
                <Nav.Link href="/quote" style={navLinkStyle}>
                  <FormattedMessage id="nav.link.quote" />
                </Nav.Link>
                <Nav.Link href="/contact-us" style={navLinkStyle}>
                  <FormattedMessage id="nav.link.contact-us" />
                </Nav.Link>
              </Nav>
              <NavDropdown
                title={useIntl().formatMessage({
                  id: "nav.link.manual",
                  description: "手册下载",
                })}
                className="dropdown-manual"
                style={navLinkStyle}
              >
                <NavDropdown.Item
                  className="custom-dropdown-item"
                  href="https://api.prod.chifeng.io/static/shipper_app_flow_manual.pdf"
                  target="_blank"
                  style={dropdownStyle}
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.shipper.app",
                    description: "寄货人App端",
                  })}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="custom-dropdown-item"
                  href="https://api.prod.chifeng.io/static/carrier_app_flow_manual.pdf"
                  target="_blank"
                  style={dropdownStyle}
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.carrier.app",
                    description: "运货人App端",
                  })}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="custom-dropdown-item"
                  href="https://api.prod.chifeng.io/static/agent_portal_manual.pdf"
                  target="_blank"
                  style={dropdownStyle}
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.carrier.web",
                    description: "运货人网页端",
                  })}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                onSelect={changeLanguageEvent}
                title={
                  currentLanguage.locale === "en"
                    ? "EN"
                    : currentLanguage.locale === "zh-CN"
                    ? "中文"
                    : currentLanguage.locale
                }
                id="basic-nav-dropdown"
                align={isMobile ? "start" : "end"}
                className="dropdown-language-switch"
                style={navLinkStyle}
              >
                {languageList.map((item) => {
                  let text;
                  if (item === "en") {
                    text = "EN";
                  } else if (item === "zh-CN") {
                    text = "中文";
                  } else {
                    text = item;
                  }
                  return (
                    <NavDropdown.Item
                      className="custom-dropdown-item"
                      key={item}
                      href={item}
                      as="span"
                      style={dropdownStyle}
                    >
                      {text}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
              {isShipperLoggedIn ? (
                <NavDropdown
                  title={
                    useIntl().formatMessage({
                      id: "nav.link.user.prefix.shipper",
                      description: "寄货人账号前缀",
                    }) +
                    " " +
                    (isMobile && shipperPhone.length > 8 ? shipperPhone.substring(0, 8) + "..." : shipperPhone)
                  }
                  align={isMobile ? "start" : "end"}
                  className="dropdown-logout"
                  style={navLinkStyle}
                  onSelect={() => {
                    onShipperLogout();
                  }}
                >
                  <NavDropdown.Item className="custom-dropdown-item" style={dropdownStyle}>
                    {useIntl().formatMessage({
                      id: "nav.link.logout",
                      description: "登出",
                    })}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : isLoggedIn ? (
                <NavDropdown
                  title={
                    useIntl().formatMessage({
                      id: "nav.link.user.prefix",
                      description: "司机账号前缀",
                    }) +
                    " " +
                    (isMobile && phone.length > 8 ? phone.substring(0, 8) + "..." : phone)
                  }
                  align={isMobile ? "start" : "end"}
                  className="dropdown-logout"
                  style={navLinkStyle}
                  onSelect={(eventKey) => {
                    if (eventKey === "order") {
                      router.navigate("/orders");
                    } else if (eventKey === "logout") {
                      onLogout(false);
                    }
                  }}
                >
                  <NavDropdown.Item
                    className="custom-dropdown-item"
                    eventKey="order"
                    style={dropdownStyle}
                  >
                    {useIntl().formatMessage({
                      id: "nav.link.order",
                      description: "订单管理",
                    })}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="custom-dropdown-item"
                    eventKey="logout"
                    style={dropdownStyle}
                  >
                    {useIntl().formatMessage({
                      id: "nav.link.logout",
                      description: "登出",
                    })}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavDropdown
                  title={<FormattedMessage id="nav.link.login" />}
                  align={isMobile ? "start" : "end"}
                  className="dropdown-login"
                  style={navLinkStyle}
                >
                  <NavDropdown.Item
                    className="custom-dropdown-item"
                    href="/login"
                    style={dropdownStyle}
                  >
                    {useIntl().formatMessage({
                      id: "nav.link.login.carrier",
                      description: "我是司机",
                    })}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="custom-dropdown-item"
                    href="/quote?modal=1"
                    style={dropdownStyle}
                  >
                    {useIntl().formatMessage({
                      id: "nav.link.login.shipper",
                      description: "我要寄货",
                    })}
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
}
